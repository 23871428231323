import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { CAT, DOG, useBooleanInput } from '@pumpkincare/shared';
import { ButtonStyles, LegalBody, Modal } from '@pumpkincare/shared/ui';
import { useUserAllPetPhotos } from '@pumpkincare/user';

import PetPhotoUpload from '../pet-wrapper/pet-photo-upload';
import OnboardingComplete from './onboarding-complete';
import OnboardingPetVetAssignment from './onboarding-pet-vet-assignment';
import OnboardingReimbursement from './onboarding-reimbursement';
import OnboardingWelcome from './onboarding-welcome';

import styles from './onboarding-modal.css';

import catPlaceholder from '../shared/cat_placeholder_porkchopgray.png';
import dogPlaceholder from '../shared/dog_placeholder_porkchopgray.png';

// replace with react-query data
const DETAILS = {
  user: {
    incomplete_tasks: [
      {
        task_id: 1,
        name: 'reimbursement',
      },
    ],
    complete_tasks: [],
  },
  pets: {},
};

const PLACEHOLDERS = {
  [DOG]: dogPlaceholder,
  [CAT]: catPlaceholder,
};

function formatUserTasks(name) {
  // can add more tasks here, placeholder names for now
  return {
    isReimbursement: name === 'reimbursement',
  };
}

function formatPetTasks(name) {
  // can add more tasks here, placeholder names for now
  return {
    isVet: name === 'primary_vet',
    isPhoto: name === 'pet_photo',
  };
}

function formatViews({ user, pets }) {
  const views = [{ name: 'welcome', isWelcome: true }];

  user.incomplete_tasks.forEach(({ name }) =>
    views.push({ name, ...formatUserTasks(name) })
  );

  Object.keys(pets).forEach(id => {
    pets[id].incomplete_tasks.forEach(({ name }) =>
      views.push({ name, id, ...formatPetTasks(name) })
    );
  });

  views.push({ name: 'complete', isComplete: true });

  return views;
}

function OnboardingModal({ pets }) {
  // remove this when react-query data is available
  pets.forEach(pet => {
    DETAILS.pets[pet.id] = {
      incomplete_tasks: [
        {
          task_id: 4,
          name: 'primary_vet',
        },
        {
          task_id: 5,
          name: 'pet_photo',
        },
      ],
      complete_tasks: [],
    };
  });
  const [isModalOpen, toggleModalOpen] = useBooleanInput(true);
  const [viewIndex, setViewIndex] = useState(0);
  const { data: userPetPhotos } = useUserAllPetPhotos();

  const views = formatViews(DETAILS);

  function handleSubmit() {
    if (viewIndex === views.length - 1) {
      setViewIndex(0);
      toggleModalOpen();
      return;
    }

    setViewIndex(viewIndex + 1);
  }

  return (
    <>
      <div className={styles.root}>
        <LegalBody isBold>Set up for expedited claims.</LegalBody>
        <button className={ButtonStyles.secondary} onClick={toggleModalOpen}>
          Resume
        </button>
      </div>

      {isModalOpen ? (
        <Modal
          onClose={toggleModalOpen}
          classes={{
            container: styles.modalContainer,
          }}
        >
          {views.map((view, idx) => {
            const isView = idx === viewIndex;
            const pet = pets.find(pet => pet.id === view.id);
            const petPhoto = userPetPhotos?.find(item => item.pet_id === pet?.id);

            return (
              <div
                key={`${view.name}-${view.id || ''}`}
                className={classNames({
                  [styles.hidden]: !isView,
                })}
              >
                {view.isWelcome ? (
                  <OnboardingWelcome
                    onNextClick={handleSubmit}
                    classes={{ root: styles.viewIntro }}
                  />
                ) : null}

                {view.isReimbursement ? (
                  <OnboardingReimbursement
                    onNextClick={handleSubmit}
                    classes={{
                      root: styles.view,
                      content: styles.content,
                      subtitle: styles.subtitle,
                      ctas: styles.ctas,
                    }}
                  />
                ) : null}

                {view.isPhoto && pet ? (
                  <PetPhotoUpload
                    petName={pet.name}
                    petId={pet.id}
                    placeHolderImage={PLACEHOLDERS[pet.species]}
                    petPhoto={petPhoto?.location}
                    onSave={handleSubmit}
                    isOnboarding
                    classes={{
                      root: styles.view,
                      content: styles.content,
                      subtitle: styles.subtitle,
                      ctas: styles.ctas,
                    }}
                  />
                ) : null}

                {view.isVet && pet ? (
                  <OnboardingPetVetAssignment onNextClick={handleSubmit} pet={pet} />
                ) : null}

                {view.isComplete ? (
                  <OnboardingComplete
                    onNextClick={handleSubmit}
                    classes={{
                      root: styles.view,
                      content: styles.content,
                      subtitle: styles.subtitle,
                      ctas: styles.ctas,
                    }}
                  />
                ) : null}
              </div>
            );
          })}
        </Modal>
      ) : null}
    </>
  );
}

OnboardingModal.propTypes = {
  pets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default OnboardingModal;
