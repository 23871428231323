import PropTypes from 'prop-types';

import { useBooleanInput } from '@pumpkincare/shared';
import { Body2, ButtonStyles, LoaderButton } from '@pumpkincare/shared/ui';
import {
  getReimbursementTargetAccountNumber,
  getReimbursementTargetNameOnAccount,
  usePayment,
} from '@pumpkincare/user';

import DirectDepositSetupForm from '../../direct-deposit-setup-form';

function OnboardingReimbursement({ onNextClick, classes }) {
  const { data: paymentData } = usePayment();
  const accountNumber = getReimbursementTargetAccountNumber(paymentData);
  const nameOnAccount = getReimbursementTargetNameOnAccount(paymentData);

  const [isCompleteAlreadyView, toggleIsCompleteAlreadyView] = useBooleanInput(
    !!accountNumber
  );

  return (
    <div className={classes.root}>
      {!isCompleteAlreadyView ? (
        <DirectDepositSetupForm
          isOnboarding
          onSave={onNextClick}
          onCancel={onNextClick}
          classes={classes}
        />
      ) : (
        <div className={classes.content}>
          <h3>Confirm bank account.</h3>
          <Body2 className={classes.subtitle}>
            Your claim reimbursements will be deposited directly into this account.
          </Body2>

          <Body2 isBold style={{ margin: '64px 0 8px' }}>
            Reimbursement Method
          </Body2>
          <Body2>
            Bank Account •••• {accountNumber}&nbsp;&nbsp;|&nbsp;&nbsp;
            {nameOnAccount}
          </Body2>

          <div className={classes.ctas}>
            <LoaderButton color='primary' onClick={onNextClick}>
              Confirm & Save
            </LoaderButton>
            <button
              className={ButtonStyles.tertiary}
              onClick={toggleIsCompleteAlreadyView}
            >
              Update Bank Account
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

OnboardingReimbursement.defaultProps = {
  classes: {},
};

OnboardingReimbursement.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    content: PropTypes.string,
    subtitle: PropTypes.string,
    ctas: PropTypes.string,
  }),

  onNextClick: PropTypes.func.isRequired,
};

export default OnboardingReimbursement;
