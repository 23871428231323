import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  CHECKING,
  GENERIC_CONTACT_ERROR,
  useTargetState,
} from '@pumpkincare/shared';
import { Body2, LegalBody, LoaderButton, TextField } from '@pumpkincare/shared/ui';
import { useDeleteUserAch, useMutateUserAch, usePayment } from '@pumpkincare/user';

import validateDirectDepositFields from '../../utils/validate-direct-deposit-fields';

import styles from './direct-deposit-setup-form.css';

// To test on non-prod environments, routing numbers MUST BE 222222226 according to Dwolla
function DirectDepositSetupForm({
  onSave,
  onCancel,
  isOnboarding = false,
  classes = {},
}) {
  const { isError: isPaymentError } = usePayment();
  const { mutateAsync: mutateUserAch, isLoading: isMutatingUserAch } =
    useMutateUserAch();
  const { isLoading: isDeletingUserAch } = useDeleteUserAch();
  const isLoading = isMutatingUserAch || isDeletingUserAch;

  const [errorMessage, setErrorMessage] = useState('');
  const [accountHolderName, handleAccountHolderNameChange] = useTargetState('');
  const [accountNumber, handleAccountNumberChange] = useTargetState('');
  const [accountConfirmation, handleAccountConfirmationChange] = useTargetState('');
  const [routingNumber, handleRoutingNumberChange] = useTargetState('');
  const [routingConfirmation, handleRoutingConfirmationChange] = useTargetState('');
  const [errors, setErrors] = useState({});

  const accountInputLabel = `Checking account number`;
  const accountConfirmationInputLabel = `Confirm checking account number`;

  function onSubmit(e) {
    e.preventDefault();

    setErrorMessage('');

    const errorsValidation = validateDirectDepositFields(
      accountHolderName,
      accountNumber,
      accountConfirmation,
      routingNumber,
      routingConfirmation
    );

    if (errorsValidation.hasErrors) {
      setErrors(errorsValidation);
    } else {
      setErrors({});

      mutateUserAch({
        body: {
          name_on_account: accountHolderName,
          account_number: accountNumber,
          account_type: CHECKING,
          routing_number: routingNumber,
        },
      })
        .then(() => {
          if (isOnboarding) {
            onSave();
          } else {
            onCancel();
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            if (
              error.response.data.error_type === 'account' ||
              error.response.data.error_type === 'unknown'
            ) {
              setErrorMessage(error.response.data.message);
            }
          } else {
            setErrorMessage(GENERIC_CONTACT_ERROR);
          }
        });
    }
  }

  function handleRoutingNumberFocus() {
    setErrors({
      ...errors,
      routingNumber: false,
    });
  }

  function handleAccountNumberFocus() {
    setErrors({
      ...errors,
      accountNumber: false,
    });
  }

  const titleContent = (
    <>
      <h3 className={isOnboarding ? styles.headerTitle : styles.title}>
        Add a bank account.
      </h3>
      {isOnboarding ? (
        <Body2 className={classes.subtitle}>
          Your claim reimbursements will be deposited directly into this account.
        </Body2>
      ) : (
        <LegalBody className={styles.bodyInfo}>
          Your claim reimbursements will be deposited directly into this account. To
          verify, you may receive small amounts deposited temporarily.
        </LegalBody>
      )}
    </>
  );

  return (
    <form
      onSubmit={onSubmit}
      className={classes.content}
      aria-label='directDepositForm'
    >
      {isOnboarding ? (
        <div className={styles.header}>{titleContent}</div>
      ) : (
        titleContent
      )}

      <div className={styles.inputsContainer}>
        <TextField
          label='Account holder full name'
          onChange={handleAccountHolderNameChange}
          defaultValue={accountHolderName}
          classes={{ container: styles.textField, label: styles.label }}
          error={{ hasError: errors.accountHolderName }}
          required
        />

        <TextField
          label={accountInputLabel}
          maxLength={20}
          onChange={handleAccountNumberChange}
          defaultValue={accountNumber}
          classes={{ container: styles.textField, label: styles.label }}
          onFocus={handleAccountNumberFocus}
          error={{ hasError: errors.accountNumber }}
          required
        />

        <TextField
          label={accountConfirmationInputLabel}
          maxLength={20}
          onChange={handleAccountConfirmationChange}
          defaultValue={accountConfirmation}
          classes={{ container: styles.textField, label: styles.label }}
          error={{
            errorMessage: errors.accountConfirmation
              ? `Account numbers don't match.`
              : '',
          }}
          required
        />

        <TextField
          label={'Routing number'}
          placeholder={'Must be 9 digits long'}
          maxLength={9}
          onChange={handleRoutingNumberChange}
          defaultValue={routingNumber}
          classes={{ container: styles.textField, label: styles.label }}
          onFocus={handleRoutingNumberFocus}
          error={{
            errorMessage:
              errors.routingNumber || isPaymentError
                ? (errors.routingNumber &&
                    !errors.routingConfirmation &&
                    'Please enter a 9 digit routing number') ||
                  errorMessage
                : '',
            hasError: errors.routingNumber,
          }}
          required
        />

        <TextField
          label={'Confirm routing Number'}
          maxLength={9}
          onChange={handleRoutingConfirmationChange}
          defaultValue={routingConfirmation}
          classes={{ container: styles.textField, label: styles.label }}
          error={{
            errorMessage: errors.routingConfirmation
              ? `Routing numbers don't match.`
              : '',
          }}
          required
        />
      </div>

      <div className={classNames(styles.buttonsContainer, classes.ctas)}>
        {errorMessage ? (
          <LegalBody className={styles.errorMessage}>{errorMessage}</LegalBody>
        ) : null}

        <LoaderButton
          color='primary'
          type='submit'
          isLoading={isLoading}
          disabled={isPaymentError}
          classes={{ root: styles.primary }}
        >
          {isOnboarding ? 'Confirm & Save' : 'Set up direct deposit'}
        </LoaderButton>

        <LoaderButton
          color='tertiary'
          classes={{ root: styles.cancelButton }}
          onClick={onCancel}
        >
          {isOnboarding ? 'Skip – I prefer to be reimbursed by check' : 'Cancel'}
        </LoaderButton>
      </div>
    </form>
  );
}

DirectDepositSetupForm.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  isOnboarding: PropTypes.bool,
  classes: PropTypes.shape({
    content: PropTypes.string,
    subtitle: PropTypes.string,
    ctas: PropTypes.string,
  }),
};

export default DirectDepositSetupForm;
