import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Body2, ButtonStyles, CheckIcon, LegalBody } from '@pumpkincare/shared/ui';

import styles from './onboarding-complete.css';

function OnboardingComplete({ classes = {}, onNextClick }) {
  return (
    <div className={classNames(styles.root, classes.root)}>
      <div className={classes.content}>
        <div className={styles.check}>
          <CheckIcon color='var(--zeroWhite)' />
        </div>

        <h3 className={styles.header}>You're all set.</h3>
        <Body2 className={classes.subtitle}>
          Thank you. It's time to unwind and enjoy some cozy moments with your pets.
        </Body2>
        <LegalBody className={styles.legal}>
          Insurance coverage starts after the waiting period.
        </LegalBody>

        <div className={classes.ctas}>
          <button className={ButtonStyles.primary} onClick={onNextClick}>
            All done!
          </button>
        </div>
      </div>
    </div>
  );
}

OnboardingComplete.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    content: PropTypes.string,
    ctas: PropTypes.string,
  }),
  onNextClick: PropTypes.func.isRequired,
};

export default OnboardingComplete;
